
import { defineComponent } from 'vue';
import VarmaIcon from '../../../components/common/icons/VarmaIcon.vue';

const HIDE_MS: number = 24 * 60 * 60 * 1000;

export default defineComponent({
    components: {
        VarmaIcon
    },
    props: {
        title: { type: String, required: true },
        id: { type: String, required: true }
    },
    data() {
        return {
            visible: false
        } as { 
            visible: boolean;
        };
    },
    mounted() {
        const value = parseInt(localStorage[this.id], 10) || 0;
        const diff = Math.abs(new Date().getTime() - value);
        
        if (diff > HIDE_MS) {
            this.visible = true;
        }
    },
    methods: {
        close(): void {
            localStorage[this.id] = new Date().getTime();
            this.visible = false;
        }
    }
});
