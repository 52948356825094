import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0829754e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "block alert-block"
}
const _hoisted_2 = { class: "alert-block__container" }
const _hoisted_3 = { class: "alert-block__content" }
const _hoisted_4 = { class: "alert-block__heading" }
const _hoisted_5 = { class: "alert-block__button-container" }
const _hoisted_6 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_varma_icon = _resolveComponent("varma-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_varma_icon, {
            size: "M",
            color: "status-alert",
            weight: "bold",
            name: "alert-triangle"
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              id: "close-alert-click",
              class: "alert-block__button",
              "aria-label": _ctx.$t('/common/close'),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, [
              _createVNode(_component_varma_icon, {
                size: "S",
                weight: "bold",
                name: "close",
                color: "black"
              })
            ], 8, _hoisted_6)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}